import { FC, RefObject, useEffect, useState } from 'react'
import { Placemark, withYMaps } from '@pbe/react-yandex-maps'
import { PlaceModel } from 'api/places/models/place.model'
import { PlaceMapItemCard } from 'pages/places/place-map-item-card'
import ReactDOM from 'react-dom'
import * as styles from './custom-placemark.style'
import closeBtn from 'assets/close.svg'
import { Map as MapType } from 'yandex-maps'

type CustomPlacemarkProps = {
  place: PlaceModel
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ymaps?: any
  mapRef: RefObject<MapType | undefined>
}

const CustomPlacemark: FC<CustomPlacemarkProps> = ({
  place,
  ymaps,
  mapRef,
}) => {
  const fetchImageAsBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url)
    const blob = await response.blob()
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  const [base64Image, setBase64Image] = useState<string | null>(null)

  useEffect(() => {
    fetchImageAsBase64(place.image.url).then(setBase64Image)
  }, [place.image.url])

  const svgTemplate = `
    <svg width="64" height="102" viewBox="0 0 64 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clipCircle">
          <circle cx="32" cy="34" r="26" />
        </clipPath>
      </defs>
      <path d="M4.56641 48.7168H57.85C42.6018 68.9921 37.3827 80.7247 31.2082 102C24.1831 80.6266 18.9646 68.8542 4.56641 48.7168Z" fill="#FFDE00"/>
      <circle cx="32" cy="32" r="31.6188" fill="#FFDE00"/>
      <image width="120" height="120" x="-15" y="-25" href="${base64Image}" clip-path="url(#clipCircle)"/>
    </svg>
  `

  const svgUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgTemplate)}`

  const CustomBalloonContent =
    ymaps &&
    ymaps.templateLayoutFactory.createClass(
      `<div class="${styles.balloonContent}">
        <button id="custom-balloon-close" class="${styles.balloonCloseButton}">
          <img src=${closeBtn} alt='X' />
        </button>
        <div id="custom-balloon-inner-content"></div>
      </div>`,
      {
        build: function () {
          CustomBalloonContent.superclass.build.call(this)
          const closeButton = document.getElementById('custom-balloon-close')
          closeButton?.addEventListener(
            'click',
            this.onCloseButtonClick.bind(this)
          )
        },
        clear: function () {
          const closeButton = document.getElementById('custom-balloon-close')
          closeButton?.removeEventListener(
            'click',
            this.onCloseButtonClick.bind(this)
          )
          CustomBalloonContent.superclass.clear.call(this)
        },
        onCloseButtonClick: function (e: MouseEvent) {
          e.preventDefault()
          this.getData().geoObject.balloon.close()
        },
      }
    )

  useEffect(() => {
    if (base64Image && CustomBalloonContent) {
      const balloonContentNode = document.getElementById(
        'custom-balloon-inner-content'
      )
      if (balloonContentNode) {
        ReactDOM.render(<PlaceMapItemCard place={place} />, balloonContentNode)
      }
    }
  }, [base64Image, CustomBalloonContent, place])

  if (!base64Image || !CustomBalloonContent) {
    return null
  }

  return (
    <Placemark
      geometry={[place.latitude, place.longitude]}
      options={{
        iconLayout: 'default#image',
        hideIconOnBalloonOpen: false,
        iconImageHref: svgUrl,
        iconImageSize: [64, 102],
        iconImageOffset: [-32, -102],
        balloonCloseButton: false,
        balloonLayout: CustomBalloonContent,
        balloonAutoPan: true,
      }}
      modules={['geoObject.addon.balloon']}
      onBalloonOpen={() => {
        const balloonContentNode = document.getElementById(
          'custom-balloon-inner-content'
        )
        if (balloonContentNode) {
          ReactDOM.render(
            <PlaceMapItemCard place={place} />,
            balloonContentNode
          )
        }
        if (mapRef.current) {
          const currentZoom = mapRef.current.getZoom()
          mapRef.current.setCenter(
            [+place.latitude - 0.004, +place.longitude],
            currentZoom,
            {
              duration: 300,
              checkZoomRange: true,
            }
          )
        }
      }}
    />
  )
}

export default withYMaps(CustomPlacemark, true, ['templateLayoutFactory'])
